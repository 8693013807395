<template>
  <div class="banner-ia-home-search row m-0 h-100">
    <div class="container-img-banner col-12 col-md-6 p-0">
      <transition :name="'fade'">
        <div :key="current_image">
          <b-img :src="require(`@/assets/images/banner/${current_image}.png`)" class="img-banner"></b-img>
          <div class="gradient"></div>
        </div>
      </transition>
    </div>
    <div class="text-container-ia-banner col-12 col-md-6 px-3 h-100">
      <div class="d-flex flex-wrap justity-content-center align-items-center h-100">
        <div>
          <div class="w-100 mb-1 text-uppercase d-flex align-items-center" >          
            <b-img :src="require('@/assets/images/svg/stars.svg')" class="svg-icon-active"></b-img>
            {{ $t('bannerIA.tools') }}
          </div>          
          <h1 class="title-main-banner">
            {{ $t('bannerIA.tryitText') }} 
            <transition :name="'fade'">
              <div :key="current_image">
                <span>{{ $t(`bannerIA.${images[index_image].text}`) }}</span>
              </div>
            </transition>
          </h1>
          <b-button class="button-try-ia" @click="checkRedirect()">{{ $t('bannerIA.tryit') }}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BImg, BButton } from 'bootstrap-vue';

export default {
  title: 'BrandmeIaBanner',
  components: {
    BImg,
    BButton,
  },
  props: {
    open_new_tab: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      first_time_transition: true,
      images: [
        {
          image: 'BrandMe_IA_FotoCyberpunk_11',
          text: 'copiesForInstagramPosts',
        },
        {
          image: 'BrandMe_IA_FotoPlantas_12',
          text: 'blogNotes',
        },
        {
          image: 'BrandMe_IA_HombreLunaGuitarra_8',
          text: 'videosWithAI',
        },
        {
          image: 'BrandMe_IA_JugadorSuperBowl_9',
          text: 'tweetsAndPosts',
        },
        {
          image: 'BrandMe_IA_LevantandoCopa_10',
          text: 'scriptsForVideos',
        },
        {
          image: 'BrandMe_IA_MujerCoachella_7',
          text: 'productReview',
        },
        {
          image: 'BrandMe_IA_MujerNY_3',
          text: 'examplesOfProductPlacement',
        },
        {
          image: 'BrandMe_IA_MuseoArte_4',
          text: 'celebritiesWithProduct',
        },
        {
          image: 'BrandMe_IA_Perro_5',
          text: 'spokenPortraits',
        }
      ],
      main_interva: null,
      index_image: 0,
      current_image: 'BrandMe_IA_FotoCyberpunk_11'
    }
  },
  mounted() {
    this.startInterval();
  },
  methods: {
    checkRedirect() {
      if (this.open_new_tab) {
        const url = `${window.location.origin}/brandme-AI/community`
        window.open(url, '_blank');
      } else {
        this.$router.push({name: 'brandme_AI', params: {section: 'community'}})
      }
    },
    startInterval() {
      this.main_interval = setInterval(() => {
        this.index_image = (this.index_image + 1) % this.images.length;
      }, 6000);
    }
  },
  watch: {
    index_image(val) {
      this.current_image = this.images[val].image;
    },
  }
};
</script>
<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity 6s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
<style lang="scss" scoped>
.banner-ia-home-search {


  background-color: #421150;
  // background-color: gray;
  width: 100%;
  height: 450px;
  border-radius: .625rem;
  overflow: hidden;

  .container-img-banner {
    height: 100%;
    position: relative;
    min-height: 450px;
    .img-banner {
      width: 100%;
      min-height: 450px;
      height: 100%;
      object-fit: cover;
      position: absolute;
    }
    .gradient {
      width: 100%;
      background: radial-gradient(closest-side , rgba(66, 17, 80, 0), #421150);
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      min-height: 450px;
      height: 100%;
    }
  }
  .text-container-ia-banner {
    color: white !important;
    .title-main-banner {
      color: white !important;
      position: relative;
      span {
        position: absolute;
        background: linear-gradient(to left, #ed2376, #9437ff);
        -webkit-background-clip: text !important;
        -webkit-text-fill-color: rgba(0,0,0,0) !important;
        // margin-left: 0.2em;
      }
    }
    .button-try-ia {
      background: linear-gradient(to left, #ed2376, #9437ff);
      border: none;
      margin-top: 5em;
      @media(max-width: 700px) {
        margin-top: 7em;
      }
    }

    @media(max-width: 767px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .svg-icon-active {
    filter: invert(80%) sepia(99%) saturate(2200%) hue-rotate(300deg) brightness(90%) contrast(91%);
    width: 30px;
    height: 30px;
    margin-right: 0.5em;
  }
}
</style>